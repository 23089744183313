<template>
    <div class="main-banner">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content">
                        <h1>Banking services for entrepreneurs</h1>
                        <p>Access the technology, tools and insights to build, and grow your own financial services.</p>
                        <a href="mailto:projects@bankeasyng.com" class="btn btn-primary">CONTACT US</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MainBanner'
    }
</script>