<template>
    <div class="services-area ptb-70">
        <div class="container-fluid p-0">
            <div class="overview-box">
                <div class="overview-content">
                    <div class="content left-content">
                        <h2>Large or enterprise level businesses</h2>
                        <div class="bar"></div>
                        <p>Become a bank in your own rights</p>

                        <ul class="services-list">
                            <li><span><i class="flaticon-check-mark"></i> Account Opening</span></li>
                            <li><span><i class="flaticon-check-mark"></i> Card Issuance</span></li>
                            <li><span><i class="flaticon-check-mark"></i> Loan Services</span></li>
                            <li><span><i class="flaticon-check-mark"></i> Revenue Collection</span></li>
                            <li><span><i class="flaticon-check-mark"></i> Full Data Compliance</span></li>
                            <li><span><i class="flaticon-check-mark"></i> Transaction Reconciliation</span></li>
                        </ul>
                    </div>
                </div>

                <div class="overview-image">
                    <div class="image">
                        <img src="../../assets/images/businesses-3.png" alt="image">

                        <div class="circle-img">
                            <img src="../../assets/images/circle.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ServicesThree'
    }
</script>