<template>
	<div class="privacy-policy-area ptb-100">
		<div class="container">
			<div class="section-title">
				<h2>Data Privacy Policy</h2>

				<p>This policy was last updated October 27, 2024.</p>
			</div>
			<div class="privacy-policy-content">
				<h3>INTRODUCTION</h3>
				<p>
					As a customer, be assured that at Bankeasy Microfinance Bank Ltd. (hereinafter called
					“Bankeasy”), we are dedicated to protecting your privacy and providing you with the
					highest level of security at any point of interaction with us. Hence, we want you to
					engage with us knowing that we value your Personal Data and that we protect it. This
					Privacy Policy explains what information we collect, and how we collect, share, use, and
					protect your personal information when you visit or use this site and other services
					offered by Bankeasy. You are also informed of your rights regarding the information we
					process and how you can contact us. By continuing to visit our websites
					(www.bankeasyng.com) and other Bankeasy customer touchpoints, you accept and consent to
					the practices described in this policy.
				</p>
			</div>
			<div class="privacy-policy-content">
				<h3>PERSONAL INFORMATION COLLECTED AND COLLECTION METHODS</h3>
				<p>
					Personal information refers to data that could identify a specific individual such as
					names, addresses, e-mail addresses, and telephone numbers. We collect information about
					you from various sources such as website visits, applications, identification documents,
					and personal financial statements, interactions with our relationship management officers,
					and other third parties (credit bureaus, payment gateways, etc.), and other written or
					electronic communications. Depending on your medium of interaction with Bankeasy, we
					collect various types of information from you, as described below.
				</p>

				<ul>
					<li>
						Personal details (e.g. name, date of birth, passport information or other
						identification information)
					</li>
					<li>
						Contact details (e.g. phone number, email address, postal address or mobile number)
					</li>
					<li>Transactional details (e.g. payments you make and receive)</li>
					<li>
						Biometric information (e.g. fingerprints, facial recognition, or voice recognition)
					</li>
					<li>
						Financial information (e.g. bank account number, debit card numbers, financial
						history) including information you provide to deliver payment initiation services and
						account information services regarding accounts you hold with other providers
					</li>
					<li>Education and employment information.</li>
					<li>
						Information about your family, lifestyle, and social circumstances (such as
						dependents, marital status, next of kin, and contact details);
					</li>
					<li>
						Information about any other Bankeasy products and services you currently have, you
						have applied for, or you have previously held.
					</li>
					<li>
						Visual images and personal appearance (such as copies of passports or CCTV images)
					</li>
					<li>
						Online profile and social media information and activity, based on your interaction
						with us and our websites and applications, including for example your banking profile
						and login information, Internet Protocol (IP) address, smart device information,
						location coordinates, online and mobile banking security authentication, mobile phone
						network information, searches, and site visits.
					</li>
				</ul>
			</div>

			<div class="privacy-policy-content">
				<h3>CONDITION FOR PROCESSING PERSONAL DATA</h3>
				<p>
					Bankeasy personnel or any third party acting on its behalf shall only process your data if
					at least one of these conditions are met:
				</p>

				<ul>
					<li>
						Consent: this refers to any freely given, specific, informed, and unambiguous
						indication through a statement or a clear affirmative action that signifies your
						agreement to the processing of your Personal Data by Bankeasy. Bankeasy shall not seek
						consent that may engender direct or indirect propagation of atrocities, hate, criminal
						acts, and anti-social conduct.
					</li>
					<li>
						Contract: processing is necessary for the performance of a contract or entering a
						contract at your request.
					</li>
					<li>
						Legal obligation: processing is necessary for compliance with a legal obligation to
						which Bankeasy is subject.
					</li>
					<li>
						Vital interest: processing is necessary to protect your vital interests or those of
						another natural person.
					</li>
					<li>
						Public interest: the processing is necessary for the performance of a task carried out
						in the public interest or the exercise of official authority vested in Bankeasy.
					</li>
					<li>
						Vendors, Consultants, and Third-party Service Providers: processing is required for
						legitimate interests of Bankeasy or a third party insofar as this does not conflict
						with the requirements for the protection of your data.
					</li>
				</ul>
			</div>

			<div class="privacy-policy-content">
				<h3>HOW TO USE YOUR PERSONAL DATA</h3>
				<p>
					To the extent permissible under applicable law, we may use your information for the
					following legitimate actions:
				</p>

				<ul>
					<li>Providing and operating the products and services you have requested</li>
					<li>
						For other related purposes which may include updating and enhancing Bankeasy records,
						understanding your financial needs, conducting credit checks, reviewing
						creditworthiness, and assisting other financial institutions in conducting credit
						checks
					</li>
					<li>
						Identifying and informing you about other products or services that we think may be of
						interest to you
					</li>
					<li>Reviewing credit or loan eligibility</li>
					<li>For crime/fraud prevention and debt collection purposes</li>
					<li>To plan, conduct, and monitor Bankeasy’s business</li>
					<li>
						For improving the design and marketing of our range of services and related products
						for customer use.
					</li>
					<li>
						Compare information for accuracy and verify it with third parties/publicly available
						information.
					</li>
					<li>Manage our relationships with you</li>
					<li>
						To monitor, carry out statistical analysis and benchmarking to identify potential
						markets and trends, evaluate and improve our business
					</li>
					<li>
						Monitor activities at our facilities, including compliance with applicable policies
					</li>
					<li>
						For purposes required by law or regulation without your personal information, we may
						not be able to provide or continue to provide you with the products or services that
						you need.
					</li>
				</ul>
			</div>
			<div class="privacy-policy-content">
				<h3>TRANSFER OF PERSONAL DATA</h3>
				<p>
					Personal data collected by Bankeasy may be transferred among its various divisions (with
					personnel who have business need to know). Other than to those individuals and entities
					listed below, your details will not be revealed by Bankeasy to any external body unless
					Bankeasy has your permission or is under either a legal obligation or any other duty to do
					so. For the purposes detailed above, your information may be disclosed to:
				</p>

				<ul>
					<li>Other Branches in Bankeasy Microfinance Bank Ltd</li>
					<li>
						Any regulatory, supervisory, governmental, or quasi-governmental authority with
						jurisdiction over Bankeasy
					</li>
					<li>
						Any agent, contractor or third-party service provider, professional adviser, or any
						other person under a duty of confidentiality to Bankeasy Microfinance Bank Ltd.
					</li>
					<li>Credit reference agencies and, in the event of default, debt collection agencies.</li>
					<li>
						Any actual or potential participant, sub-participant, assignee, novatee, or transferee
						of any of Bankeasy’s rights and/or obligations related to you.
					</li>
					<li>
						Any financial institution with which Bankeasy Microfinance Bank Ltd has or proposes to
						have dealings.
					</li>
				</ul>

				<p>
					The above disclosures may require the transfer of your information to parties located in
					countries that do not offer the same level of data protection as your home country.
					However, Bankeasy Microfinance Bank Ltd shall ensure that parties to whom your details are
					transferred treat your information securely and confidentially.
				</p>
			</div>
			<div class="privacy-policy-content">
				<h3>HOW WE ENSURE PROTECTION OF YOUR PERSONAL DATA</h3>
				<p>
					We have implemented appropriate organizational and technical measures (including physical
					access controls and secure software and operating environments) to keep your Personal Data
					confidential and secure. Please note, however, that these protections do not apply to
					information you choose to share in public areas such as third-party social networks. Where
					we have provided you (or where you have chosen) a password that grants you access to
					specific areas on our site, you are responsible for keeping this password confidential. We
					request that you do not share your password or other authentication details (e.g. token
					generated codes) with anyone.
				</p>
			</div>
			<div class="privacy-policy-content">
				<h3>PERSONAL DATA BREACH NOTIFICATION</h3>
				<p>
					Bankeasy will inform relevant authorities and if necessary affected individuals of
					personal data breach within 72 hours of being aware of the breach, where Personal Breach
					refers to a breach of security leading to the accidental or unlawful destruction, loss,
					alteration, unauthorized disclosure of, or access to, personal data. This includes
					breaches that are the result of both accidental and deliberate causes. Remedies shall
					include but not limited to investigating and reporting to appropriate authorities,
					recovering the personal data, correcting it, and/or enhancing controls around it.
				</p>
			</div>
			<div class="privacy-policy-content">
				<h3>YOUR RIGHTS</h3>
				<p>
					Bankeasy Microfinance Bank Ltd would like to make sure you are fully aware of all your
					data protection rights. Every customer is entitled to the following:
				</p>

				<ul>
					<li>
						The right to be informed – To emphasize the need for transparency over the usage of
						personal data, we ensure fair processing of information typically through this privacy
						policy.
					</li>

					<li>
						The rights to access – You have the right to request from Bankeasy for copies of your
						personal data where those requests are reasonable and permitted by law or regulation.
						We may charge you a small fee for this service.
					</li>

					<li>
						The right to rectification – You have the right to request that Bankeasy correct any
						information you believe is inaccurate. You also have the right to request Bankeasy
						Microfinance Bank Ltd. to complete information you believe is incomplete.
					</li>

					<li>
						The right to restrict processing – You have a right to ‘block’ or withdraw your
						consent to our processing of your information, which you can do at any time. When
						processing is restricted, we are permitted to store the personal data, but not further
						process it.
					</li>
					<li>
						The right to erasure – You have the right to request the deletion or removal of
						personal data where there is no compelling legal or regulatory requirement for its
						continued processing. Bankeasy shall make sure that this right is protected.
					</li>
					<li>
						The right to data portability – You have the right to request that the bank transfer
						the data that we have collected to another organization, or directly to you, under
						certain conditions. We will ensure that personal data is moved, copied, or transferred
						easily from one IT environment to another in a safe and secure way, without hindrance
						to usability.
					</li>
					<li>
						The right to object – You have the right to object to our processing of your
						information if there are compelling legitimate grounds to do so and to the extent
						permitted by law or regulation. To exercise your right(s), please contact the Data
						Protection Office of Bankeasy.
					</li>
				</ul>

				<p>You also have the right to:</p>

				<ul>
					<li>
						The right to receive your Personal Data in a commonly used and machine-readable format
						and the right to transmit these data to another Data Controller when the processing is
						based on (explicit) consent or when the processing is necessary for the performance of
						a contract.
					</li>
					<li>
						The right to lodge a complaint with the National IT Development Agency (NITDA) where
						you believe our processing of your data violates the requirements of the Nigeria Data
						Protection Regulation 2019 (NDPR).
					</li>
				</ul>
			</div>
			<div class="privacy-policy-content">
				<h3>PERSONAL DATA RETENTION PERIOD</h3>
				<p>
					Information held about you is retained if the purpose for which the information was
					collected continues. The information is then destroyed unless its retention is required to
					satisfy legal, regulatory, or accounting requirements or to protect Bankeasy’s interest.
					Please note that regulations may require the Bank to retain your personal data for a
					specified period even after the end of your banking relationship with us. It is your
					responsibility to maintain the secrecy of any user ID and login password you hold.
				</p>
			</div>
			<div class="privacy-policy-content">
				<h3>USE OF COOKIES</h3>
				<p>
					Cookies are text files placed on your computer to collect standard Internet log
					information and visitor behavior information. When you visit our websites, we may collect
					information from you automatically through cookies or similar technology, to improve your
					experience while on our websites. We would like to let you know a few things about our
					cookies:
				</p>

				<ul>
					<li>Some cookies are essential to access certain areas of this site.</li>
					<li>
						We use analytics cookies to help us understand how you use our site to discover what
						content is most useful to you
					</li>
				</ul>

				<p>
					Bankeasy Microfinance Bank Ltd. uses cookies in a range of ways to improve your experience
					on our website, including:
				</p>

				<ul>
					<li>
						Allow us to recognize the PC you are using when you return to our web site so that we
						can understand your interest in our web site and tailor its content and advertisements
						to match your interests (This type of cookie may be stored permanently on your PC but
						does not contain any information that can identify you personally).
					</li>
					<li>
						Identify you after you have logged in by storing a temporary reference number in the
						cookie so that our web server can conduct a dialogue with you while simultaneously
						dealing with other customers. (Your browser keeps this type of cookie until you log
						off or close your browser when these types of cookie are normally deleted. No other
						information is stored in this type of cookie).
					</li>
					<li>
						Allow you to carry information across pages of our site and avoid having to re-enter
						that information.
					</li>
					<li>
						Allow you access to stored information if you register for any of our online services.
					</li>
					<li>
						Enable us to produce statistical information (anonymous) which helps us to improve the
						structure and content of our website.
					</li>
					<li>Enable us to evaluate the effectiveness of our advertising and promotions.</li>
				</ul>

				<p>
					Cookies do not enable us to gather personal information about you unless you give the
					information to our server. Most Internet browser software allows the blocking of all
					cookies or enables you to receive a warning before a cookie is stored. For further
					information, visit allaboutcookies.org.
				</p>
			</div>
			<div class="privacy-policy-content">
				<h3>MAINTAINING ACCURATE INFORMATION</h3>
				<p>
					You are responsible for making sure the information provided to the Bank is accurate and
					should inform the Bank of any changes as it occurs, this will enable us to update your
					information with us.
				</p>
			</div>
			<div class="privacy-policy-content">
				<h3>PRIVACY OF CHILDREN</h3>
				<p>
					Bankeasy respects the privacy of children. We do not knowingly collect names, email
					addresses, or any other personally identifiable information from children through the
					Internet or other touch points. We do not allow children under the age of 18 to open
					accounts nor provide online banking for children less than 18 years of age without the
					consent of a guardian. Our website may include linked 3rd party sites that would be of
					interest to children. We are not responsible for the privacy and security practices of
					these sites. Parents should review the privacy policies of these sites closely before
					allowing children to provide any personally identifiable information.
				</p>
			</div>
			<div class="privacy-policy-content">
				<h3>ELECTRONIC COMMUNICATION</h3>
				<p>
					In order to maintain the security of our systems, protect our staff, record transactions,
					and, in certain circumstances prevent and detect crime or unauthorized activities,
					Bankeasy Microfinance Bank Ltd reserves the right to monitor all electronic communications
					to make sure that they comply with our legal and regulatory responsibilities and internal
					policies.
				</p>
			</div>
			<div class="privacy-policy-content">
				<h3>SOCIAL MEDIA</h3>
				<p>
					We operate and communicate through our designated channels, pages, and accounts on some
					social media sites to inform, help, and engage with our customers. We monitor and record
					comments and posts made about us on these channels so that we can improve our services.
					The public can access and read any information posted on these sites. Please note that any
					content you post to such social media platforms is subject to the applicable social media
					platform’s terms of use and privacy policies. We recommend that you review the information
					carefully to better understand your rights and obligations regarding such content.
				</p>
			</div>
			<div class="privacy-policy-content">
				<h3>CONTACTING YOU</h3>
				<p>
					In providing your telephone, facsimile number, postal and e-mail address, or similar
					details, you agree that Bankeasy may contact you by these methods to keep you informed
					about Bankeasy products and services or for any other reason. If you prefer not to be kept
					informed of Bankeasy Microfinance Bank Ltd products and services, please contact Bankeasy
					Microfinance Ltd by E-mail (info@bankeasyng.com) or through any of our branches.
				</p>
			</div>
			<div class="privacy-policy-content">
				<h3>LINKS TO OTHER WEBSITES</h3>
				<p>
					Our website and related websites may have links to or from other websites. Although we try
					to link only to websites that also have high privacy standards, we are not responsible for
					their security, privacy practices, or content. We recommend that you always read the
					privacy and security statements on these websites.
				</p>
			</div>
			<div class="privacy-policy-content">
				<h3>PRIVACY UPDATES</h3>
				<p>
					Bankeasy Microfinance Bank Ltd reserves the right to amend its prevailing Data Protection
					and Privacy Statement at any time and will place any such amendments on our websites
					(www.bankeasyng.com). The latest version of our privacy statement will replace all earlier
					versions unless it says differently. Please check back frequently to see any updates or
					changes to our Notice. This policy is not intended to, nor does it, create any contractual
					rights whatsoever or any other legal rights, nor does it create any obligations on
					Bankeasy Microfinance Bank Ltd in respect of any other party or on behalf of any party.
				</p>
			</div>
			<div class="privacy-policy-content">
				<h3>PRIVACY CONTACT INFORMATION</h3>
				<p>
					If you have any questions, concerns, or comments about our privacy policy, you may contact
					our Data Protection Officer. Kindly address your request to “The Data Protection Officer”
					at 3 Palace Way, Osun State, Nigeria. If you have any further questions or comments about
					us or our policies, please do not hesitate to contact us.
					<br />
					<b
						>Email us at: dpo@bankeasyng.com
						<br />
						Call us: +2348163260399</b
					>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "PrivacyPolicyArea",
};
</script>
