<template>
    <div class="services-area ptb-70 bg-f7fafd">
        <div class="container-fluid p-0">
            <div class="overview-box">
                <div class="overview-image">
                    <div class="image">
                        <img src="../../assets/images/businesses-2.png" alt="image">

                        <div class="circle-img">
                            <img src="../../assets/images/circle.png" alt="image">
                        </div>
                    </div>
                </div>

                <div class="overview-content">
                    <div class="content">
                        <h2>Small- to medium-sized businesses</h2>
                        <div class="bar"></div>
                        <p>Bank up your business and offer financial services to your customers</p>

                        <ul class="services-list">
                            <li><span><i class="flaticon-check-mark"></i> Account Opening</span></li>
                            <li><span><i class="flaticon-check-mark"></i> Easy Transfers</span></li>
                            <li><span><i class="flaticon-check-mark"></i> Instant Deposits</span></li>
                            <li><span><i class="flaticon-check-mark"></i> Plug and Play APIs</span></li>
                            <li><span><i class="flaticon-check-mark"></i> KYC/KYB Compliance</span></li>
                            <li><span><i class="flaticon-check-mark"></i> Buy Now, Pay Later</span></li>
                            <li><span><i class="flaticon-check-mark"></i> Collect Loans</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ServicesTwo'
    }
</script>