<template>
    <div class="featured-boxes-area">
        <div class="container">
            <div class="featured-boxes-inner">
                <div class="row m-0">
                    <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                        <div class="single-featured-box">
                            <div class="icon color-fb7756">
                                <i class="flaticon-piggy-bank"></i>
                            </div>

                            <h3>Transparent Pricing</h3>
                            <!-- <p>Lorem ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan.</p> -->

                            <!-- <router-link to="/features-1" class="read-more-btn">Read More</router-link> -->
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                        <div class="single-featured-box">
                            <div class="icon color-facd60">
                                <i class="flaticon-data-encryption"></i>
                            </div>

                            <h3>Fully Encrypted</h3>
                            <!-- <p>Lorem ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan.</p> -->

                            <!-- <router-link to="/features-1" class="read-more-btn">Read More</router-link> -->
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                        <div class="single-featured-box">
                            <div class="icon color-1ac0c6">
                                <i class="flaticon-wallet"></i>
                            </div>

                            <h3>Instant Payout</h3>
                            <!-- <p>Lorem ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan.</p> -->

                            <!-- <router-link to="/features-1" class="read-more-btn">Read More</router-link> -->
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                        <div class="single-featured-box">
                            <div class="icon">
                                <i class="flaticon-shield"></i>
                            </div>

                            <h3>Safe and Secure</h3>
                            <!-- <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan.</p> -->

                            <!-- <router-link to="/features-1" class="read-more-btn">Read More</router-link> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Featured'
    }
</script>