<template>
    <div class="account-create-area">
        <div class="container">
            <div class="account-create-content">
                <h2>Get Started</h2>
                <p>Request for a Demo</p>
                <a href="mailto:projects@bankeasyng.com" class="btn btn-primary">CONTACT US</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AccountCreate'
    }
</script>