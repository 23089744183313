<template>
	<div class="privacy-policy-area ptb-100">
		<div class="container">
			<div class="section-title">
				<h2>Bankeasy Terms and Conditions of Use</h2>
			</div>
			<div class="privacy-policy-content">
				<p>
					These are the Terms of Service (“Terms”) governing your use and access to all products,
					subscriber-based services, mobile applications (“Services”), and websites offered by
					<b>Bankeasy Microfinance Bank Ltd.</b> (“Bankeasy”) or its branches or affiliates. These
					Terms represent a legally binding agreement between you and Bankeasy. Please read the
					Terms carefully and ensure you understand them before accessing any of our Services. Your
					use of our Services is subject to these Terms and our Privacy Policy, which we advise you
					to review carefully.
				</p>
				<h3>ACCEPTING THESE TERMS</h3>
				<p>
					By using or accessing our Services, you represent that you have read and understood these
					Terms and our Privacy Policy and you agree to be bound by both. If you do not agree to the
					Terms and the Privacy Policy, do not use or access our Services.
				</p>
			</div>
			<div class="privacy-policy-content">
				<h3>ACCOUNTS</h3>
				<p>
					You must operate an account with Bankeasy to be able to use the Platform. Our Services can
					be accessed on a computer, laptop device, and/or mobile device running an operating system
					supported by us. To log onto the Platform, you will need to enter your User ID and other
					security information that we request, or when available, by using biometric authentication
					which is a security process that relies on your unique biological characteristics to
					verify your identity. The current supported biometric authentication methods are touch ID
					and face ID methods.
				</p>
				<p>
					You understand that when using biometric authentication to log onto the Platform, any
					fingerprint, facial map, or any other biometric data stored on your mobile device can be
					used to access the Platform and your accounts and to give instructions for certain
					transactions. You, therefore, should ensure that your mobile device only stores your
					fingerprint(s), facial map, or other biometric data, and will not store anyone else’s
					biometric credentials (e.g. fingerprint, facial map, or any other biometric data).
				</p>
				<p>
					The biometric authentication on the Platform is performed by the biometric authentication
					module of your device, and we do not control the functionality of any mode of biometric
					authentication including fingerprint, touch ID or face ID. We make no representation or
					warranty as to the security of the biometric authentication function on your mobile device
					and we do not have access to your fingerprint(s) or facial recognition information. For
					more information on how the biometric functionality works for your mobile device, please
					refer to your device manufacturer’s support resources.
				</p>
				<p>
					You understand and agree that any fingerprint stored on your mobile device can be used to
					access your account with Bankeasy MFB, therefore you shall take all reasonable measures to
					keep your mobile device and the password used to register your fingerprint(s), facial map
					or other biometric data on your mobile device in your safe custody, and to prevent
					unauthorized use or disclosure of your mobile device and the password. You shall be fully
					responsible for and bear the risk of any accidental or unauthorized disclosure of your
					mobile device and password to any other person or any unauthorized use of your mobile
					device and password by any other person.
				</p>
				<p>
					We may notify you from time to time about changes in the security information. We will
					automatically log you out of the Platform if you are inactive for 3 minutes.
				</p>
				<p>
					If you create an account via the Platform, you are responsible for maintaining the
					security of your account and you are fully responsible for all activities that occur under
					the account and any other actions taken in connection with it. Providing false contact
					information of any kind may result in the termination of your account. You must
					immediately notify us of any unauthorized uses of your account or any other breaches of
					security. We will not be liable for any acts or omissions by you, including any damages of
					any kind incurred as a result of such acts or omissions.
				</p>
			</div>
			<div class="privacy-policy-content">
				<h3>QUALIFICATION FOR USE</h3>
				<p>
					Accounts are available only to people aged 18 or over. You must be over the age of 18 to
					operate an account with the Company and use the Platform. Individuals under the age of 18,
					may utilize the service only with the involvement of a parent or legal guardian, under
					such person’s account and otherwise subject to these Terms. The User must have the
					contractual capacity to enter a binding contract with us and must not be barred from doing
					so under any applicable laws. You also warrant that any registration information that you
					submit to the Company is true, accurate, and complete, and you always agree to keep it
					that way and the Company will not be held liable for any loss or damage that may arise in
					this respect.
				</p>
			</div>
			<div class="privacy-policy-content">
				<h3>NO ENDORSEMENT</h3>
				<p>
					Bankeasy does not represent or endorse the accuracy or reliability of any content posted
					on any interactive area, and you acknowledge that any reliance on such content is at your
					sole risk. Content placed by users on any interactive area represents the views of the
					user and does not represent the views of Bankeasy. Our Services may contain links to
					websites owned and operated by third parties (the “External Sites”). Bankeasy is not
					responsible for the availability of, or the content located on, any External Site. You
					should contact the administrator or webmaster for the External Sites if you have concerns
					regarding links or content.
				</p>
			</div>
			<div class="privacy-policy-content">
				<h3>OWNERSHIP OF INTELLECTUAL PROPERTY</h3>
				<p>
					All content on our websites and applications, including but not limited to designs, text,
					graphics, images, video, information, software, music, source code, compilations, and
					sound (“Content”), are the proprietary property of Bankeasy. You are not permitted to
					modify, copy, distribute, frame, reproduce, download, or display any Content without
					Bankeasy’s prior written permission. Unauthorized use of the Content may violate
					applicable copyright and trademark laws and will terminate the license granted herein.
				</p>
			</div>
			<div class="privacy-policy-content">
				<h3>INDEMNIFICATION</h3>
				<p>
					You agree to indemnify, defend, and hold harmless Bankeasy and its affiliates, officers,
					directors, agents, contractors, licensors, service providers, subcontractors, suppliers,
					and employees from any claim or demand, including reasonable attorneys’ fees, arising from
					your breach of these Terms or violation of any law or third-party rights. Bankeasy
					reserves the right to assume the exclusive defense of any matter subject to
					indemnification by you, and you will cooperate in asserting any available defenses.
				</p>
			</div>
			<div class="privacy-policy-content">
				<h3>FEES</h3>
				<p>
					We charge fees and commissions as it may deem appropriate for the use of this service. Our
					fees are charged in line with applicable laws and reflect Bankeasy’s core values. We are
					committed to being transparent and will disclose all applicable fees on all transactions.
				</p>
			</div>
			<div class="privacy-policy-content">
				<h3>LIMITATION OF LIABILITY</h3>
				<p>
					You agree that, to the extent permitted by applicable law, your sole and exclusive remedy
					for any problems or dissatisfaction with the Services, the third-party applications, or
					the third-party application content is to uninstall any Bankeasy MFB Bank software and to
					stop using the Services, the third-party applications or the third-party application
					content.
				</p>
				<p>
					TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL THE COMPANY, ITS OFFICERS,
					SHAREHOLDERS, EMPLOYEES, AGENTS, DIRECTORS, SUBSIDIARIES, AFFILIATES, SUCCESSORS, ASSIGNS,
					SUPPLIERS, OR LICENSORS BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE,
					EXEMPLARY OR CONSEQUENTIAL (INCLUDING LOSS OF USE, DATA, BUSINESS, OR PROFITS) DAMAGES,
					ARISING OUT OF THE USE OR INABILITY TO USE THE SERVICES, REGARDLESS OF ANY LEGAL THEORY,
					WITHOUT REGARD TO WHETHER THE COMPANY HAS BEEN WARNED OF THE POSSIBILITY OF THOSE DAMAGES,
					AND EVEN IF A REMEDY FAILS OF ITS ESSENTIAL PURPOSE, THE AGGREGATE LIABILITY FOR ALL
					CLAIMS RELATING TO THE SERVICES, SHALL NOT BE MORE THAN THE AMOUNTS PAID BY YOU TO THE
					COMPANY DURING THE PRIOR THREE MONTHS IN QUESTION. THESE LIMITATIONS WILL APPLY TO ALL
					CAUSES OF ACTION, WHETHER ARISING FROM BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE) OR
					ANY OTHER LEGAL THEORY.
				</p>
				<p>
					Nothing in these Agreements removes or limits the Company’s liability for fraud,
					fraudulent misrepresentation, death or personal injury caused by its negligence.
				</p>
			</div>
			<div class="privacy-policy-content">
				<h3>DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</h3>
				<p>
					The Services and all information, content, materials, products, and other services
					provided by Bankeasy are offered on an “As Is” and “As Available” basis unless otherwise
					specified in writing. Bankeasy disclaims all warranties, express or implied, including
					implied warranties of merchantability and fitness for a particular purpose. Bankeasy will
					not be liable for any damages of any kind arising from your use of the Services, including
					but not limited to direct, indirect, incidental, punitive, or consequential damages,
					unless otherwise specified in writing.
				</p>
			</div>
			<div class="privacy-policy-content">
				<h3>CAVEAT LECTOR</h3>
				<p>
					By continuing to use our Services, you acknowledge that any content you rely on from
					third-party sources is at your sole risk. Bankeasy assumes no responsibility for verifying
					third-party content.
				</p>
			</div>
			<div class="privacy-policy-content">
				<h3>REFUND POLICY</h3>
				<p>
					All transactions conducted through our Services are final unless otherwise stated in
					writing. Bankeasy reserves the right to assess refund claims on a case-by-case basis and
					will issue refunds only when necessary and in compliance with applicable regulations. For
					more details regarding refunds, please contact our customer service department.
				</p>
			</div>
			<div class="privacy-policy-content">
				<h3>GOVERNING LAW AND DISPUTE RESOLUTION</h3>
				<p>
					These Terms and any separate agreements whereby we provide you with Services shall be
					governed by and construed in accordance with the laws of the Federal Republic of Nigeria.
					Any dispute, demand, claim, or cause of action shall be settled exclusively through
					binding and confidential arbitration in Lagos, Nigeria, without regard to conflict of law
					principles that may require applying the laws of another jurisdiction.
				</p>
			</div>
			<div class="privacy-policy-content">
				<h3>NO WAIVER</h3>
				<p>
					Our failure to enforce these Terms shall not constitute a waiver of these terms, and such
					failure shall not affect the right later to enforce these Terms. We would still be
					entitled to exercise our rights in any other situation where you breach these Terms.
				</p>
			</div>
			<div class="privacy-policy-content">
				<h3>SUSPENSION OF THE PLATFORM</h3>
				<p>
					The Company can, at any time, suspend your use of the Platform in any of the following
					situations; Where concerns exist about the security of the Platform; Where it is suspected
					that your account on the Platform has been used fraudulently or in an unauthorized way;
					Where it is suspected that your account on the Platform triggers certain AML/CFT risks;
					Where there is a significantly increased risk that you will be unable to repay any
					overdraft or fulfill an obligation on any of your accounts; Where there are legal or
					regulatory obligations we have to meet. We may give you advance notice of any suspension
					and tell you why. However, we will not do so if this would compromise our reasonable
					security measures or if it is unlawful to do this. Occasionally, we may not be able to
					contact you to give you advance notice. If you have entered incorrect login details on the
					Platform on several occasions, we will suspend your access to the Platform. If this
					happens, you can reset your login details within the Platform by identifying yourself and
					answering our security questions. Otherwise, you will need to call us on our customer care
					lines or email us at <b>support@bankeasyng.com</b>
				</p>
			</div>
			<div class="privacy-policy-content">
				<h3>RIGHTS OF THIRD PARTIES</h3>
				<p>
					A person or entity who is not a party to these Terms shall have no right to enforce any
					provision of these Terms, regardless of whether such person or entity has been identified
					by name, as a member of a class, or as answering a particular description. For the
					avoidance of doubt, nothing in this Paragraph shall affect the rights of any permitted
					assignee or transferee of these Terms.
				</p>
			</div>
			<div class="privacy-policy-content">
				<h3>SEVERABILITY</h3>
				<p>
					All rights and restrictions contained in this Agreement may be exercised and shall be
					applicable and binding only to the extent that they do not violate any applicable laws and
					are intended to be limited to the extent necessary so that they will not render this
					Agreement illegal, invalid, or unenforceable. If any provision or portion of any provision
					of this Agreement shall be held to be illegal, invalid, or unenforceable by a court of
					competent jurisdiction, it is the intention of the parties that the remaining provisions
					or portions thereof shall constitute their agreement with respect to the subject matter
					hereof, and all such remaining provisions or portions thereof shall remain in full force
					and effect.
				</p>
			</div>
			<div class="privacy-policy-content">
				<h3>DISPUTE RESOLUTION</h3>
				<p>
					The validity, construction, and performance of this Agreement shall be governed by the
					laws of the Federal Republic of Nigeria.
				</p>
				<p>
					Parties shall use their best endeavors to amicably settle any dispute or difference of
					opinion arising from or in connection with this Agreement through mutual discussions in
					line with our Dispute Resolution and Complaint Management Control Framework.
				</p>
				<p>
					All disputes should be logged formally by sending an email to
					<b>support@bankeasyng.com</b> within 90 days of the transaction date
				</p>
				<p>
					Where the Parties are unable to resolve the dispute through mutual discussions, the
					dispute or difference of opinion shall be referred to mediation conducted by their legal
					representatives or financial auditors, where financial matters are involved. Failing which
					the dispute shall finally be referred to arbitration in accordance with the Arbitration
					and Conciliation Act Cap. A18, Laws of the Federation of Nigeria 2004, or any amendment
					thereto.
				</p>
				<p>
					The place of arbitration shall be Lagos State, Nigeria and there shall be a sole
					arbitrator who shall be appointed jointly by the parties. Where the parties fail to
					jointly agree on the appointment, they shall approach the Lagos Multi-Door Court House to
					appoint a sole arbitrator who shall be competent to adjudicate on the issue in dispute.
				</p>
				<p>
					The language to be used in the arbitral proceedings shall be English. The arbitral award
					shall be final and binding between the Parties. The reference of any dispute to
					arbitration shall not prevent the Parties from seeking any form of protective
					reliefs/injunctions/specific performance from a court of law via motion or other
					originating processes for the protection of any res pending the constitution of the
					Arbitral Panel and/or final award.
				</p>
			</div>
			<div class="privacy-policy-content">
				<h3>CHANGES AND AMENDMENTS</h3>
				<p>
					We reserve the right to modify this Agreement or its policies relating to the Platform or
					Services at any time, effective upon posting an updated version of this Agreement in the
					Platform. When we do, we will send you an email to notify you. Continued use of the
					Platform after any such changes shall constitute your consent to such changes.
				</p>
			</div>
			<div class="privacy-policy-content">
				<h3>CONTACTING US</h3>
				<p>
					If you have any questions about this Agreement, please contact us. Last updated in
					September 2024.
				</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "TermsCondition",
};
</script>
